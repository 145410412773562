
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { addApolloState, initializeApollo } from 'content-service';
import { footerQuery, swnzHeaderQuery, swnzTopNavQuery } from 'content-service/src/helpers';
import { GetStaticProps } from 'next';
import { CONTENT_SERVICE } from 'src/constants';
import { useTrackErrorPageViews } from 'tracking/tealium/views/error';
import { Error } from 'ui';
import { getQueryLocale } from 'utils';
const Swnz404Page = ({ preview }: {
    preview: boolean;
}) => {
    useTrackErrorPageViews();
    return <Error preview={preview} statusCode={404}/>;
};
const getStaticProps: GetStaticProps = async ({ locale, preview = false, defaultLocale, }) => {
    const apolloClient = initializeApollo({ preview: preview });
    const queryLocale = getQueryLocale(locale, defaultLocale);
    try {
        const response = await Promise.allSettled([
            swnzHeaderQuery({
                apolloClient,
                preview,
                codeId: CONTENT_SERVICE.HEADER_CODE_ID,
                locale: queryLocale
            }),
            swnzTopNavQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.SECONDARY_TOP_NAV,
                preview,
                locale: queryLocale
            }),
            swnzTopNavQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.SECONDARY_TOP_NAV_LOGGED_IN,
                preview,
                locale: queryLocale
            }),
            footerQuery({ apolloClient, preview, locale: queryLocale }),
        ]);
        response.filter((queryResponse) => {
            if (queryResponse.status !== 'fulfilled')
                console.error(`⚠️  Error in query response for 404 page`);
        });
        return addApolloState(apolloClient, {
            props: { preview, locale }
        });
    }
    catch (error) {
        return addApolloState(apolloClient, {
            props: { preview, pageError: true, locale }
        });
    }
};
export default Swnz404Page;

    async function __Next_Translate__getStaticProps__195c5ddf305__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195c5ddf305__ as getStaticProps }
  