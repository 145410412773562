import { useRouter } from "next/router";
import useDeepCompareEffect from "use-deep-compare-effect";
import { TEALIUM_EVENT } from "../helpers/constants";
import { useTealium } from "../TealiumTracking";

interface ErrorPageView {
  url: string;
  referrer: string;
}

interface ErrorPageViewResult extends ErrorPageView {
  tealium_event: TEALIUM_EVENT;
}

const useTrackErrorPageViews = () => {
  const { asPath, locale } = useRouter();
  const { setViewData, setIsPageReady } = useTealium();

  const basePath = `${process.env.NEXT_PUBLIC_URL ?? ""}/${locale}`;

  useDeepCompareEffect(() => {
    setViewData(
      createErrorPageUtagView({
        url: `${basePath}/404`,
        referrer: document?.referrer ?? `${basePath}${asPath}`,
      }),
    );
    setIsPageReady(true);
  }, [setViewData, setIsPageReady]);
};

/**
 * createErrorPageUtagView
 * Build the data for a 404_error page view event
 */
function createErrorPageUtagView({
  url,
  referrer,
}: ErrorPageView): ErrorPageViewResult {
  return {
    tealium_event: TEALIUM_EVENT.ERROR_PAGE_VIEW,
    url,
    referrer,
  };
}

export default createErrorPageUtagView;
export type { ErrorPageViewResult };
export { useTrackErrorPageViews };
